import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient, HttpContext } from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TranslateService } from '@ngx-translate/core';
import { SKIPLOADING } from '../interceptor/httpi.interceptor';
import { Country } from '../data/countries';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(
    @Inject(DOCUMENT) private dom,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private http: HttpClient,
    private cookiesService: SsrCookieService,
    private translate: TranslateService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
    // if (this.getLang()) {
    //   this.lang.next(this.getLang());
    // }
  }
  public allElements = new BehaviorSubject<ElementRef[]>([]);
  public marketing = new BehaviorSubject(false);
  public getPreviousUrl() {
    return this.previousUrl;
  }

  public user_country = new BehaviorSubject<Country>(null);
  public webviewActive = new BehaviorSubject<boolean>(false);
  public appViewActive = new BehaviorSubject<boolean>(false);
  public holograming = new BehaviorSubject<boolean>(false);
  public navbarActive = new BehaviorSubject<boolean>(true);
  public kidName = new BehaviorSubject<string>('');
  public allLang = ['en', 'fr', 'cz'];
  public canonicalCreate = false;

  base64ToBlob(base64: string) {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  checkLang() {
    const lang = this.getLang();
    if (lang) {
      if (this.allLang.includes(lang)) {
        this.setLang(lang);
        return of(true);
      } else {
        this.removeLang();
        return of(false);
      }
    } else {
      this.setLang('fr');
    }
  }
  getLang() {
    return this.cookiesService.get('lang');
  }
  setLang(lang: string) {
    if (this.getLang()) {
      this.removeLang();
    }
    this.cookiesService.set('lang', lang);
    this.translate.use(lang);
  }
  removeLang() {
    this.cookiesService.delete('lang');
  }
  getLinkPreview(url: string) {
    return this.http.get(`${environment.apiUrl}/api/getLinkPreview`, {
      params: { url: encodeURIComponent(url) },
    }) as Observable<{
      url: string;
      title: string;
      favicon: string | undefined;
      description: string | undefined;
      image: string | undefined;
      author: string | undefined;
    }>;
  }
  convertTimeToMinutes(time: string, add24 = false) {
    const [hours, minutes] = time.split(':').map(Number);

    let totalMinutes = hours * 60 + minutes;

    if (hours >= 0 && hours < 5 && add24) {
      totalMinutes += 24 * 60; // Add 24 hours (1440 minutes)
    }

    return totalMinutes;
  }

  convertMinutesToHours = (minutes: number) => {
    console.log(minutes);

    const hours =
      Math.floor(minutes / 60) >= 24
        ? Math.floor(minutes / 60) - 24
        : Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  findClosestNumberTo5 = (time1: string, time2: string) => {
    const target = new Date().setHours(5, 0, 0, 0); // Set the target time to 05:00 AM (current day)

    // Parse the time strings and convert them to Date objects
    const timeInMillis1 = new Date(`2000-01-01T${time1}`).getTime();
    const timeInMillis2 = new Date(`2000-01-01T${time2}`).getTime();
    // Calculate the differences in milliseconds from the target time
    const diff1 = Math.abs(timeInMillis1 - target);
    const diff2 = Math.abs(timeInMillis2 - target);

    // Check which time is closer to the target
    if (diff1 < diff2) {
      return time1;
    } else {
      return time2;
    }
  };
  updateCanonicalUrl() {
    this.translate.onLangChange.subscribe((v) => {
      if (!this.canonicalCreate) {
        this.addLink('canonical');
        this.canonicalCreate = true;
      }
      const index = this.allLang.indexOf(v.lang);
      if (index >= 0) {
        this.allLang.splice(index, 1);
        // this.allLang.forEach((lang) => {
        //   this.addLink('alternate', lang);
        // });
      }
    });
  }
  addLink(typeLink: string, lang?: string) {
    let link: HTMLLinkElement = this.dom.createElement('link');
    this.dom.head.appendChild(link);
    link.setAttribute('rel', typeLink);
    let newUrl: string;
    if (isPlatformBrowser(this.platformId)) {
      if (lang !== undefined) {
        link.setAttribute('hreflang', lang);

        newUrl =
          this.document.location.origin +
          '/' +
          lang +
          this.document.location.pathname.substring(3);
      }
      link.setAttribute(
        'href',
        typeLink === 'canonical' ? this.document.location.href : newUrl
      );
    }
  }
  // async downloadAsPDF(htmlToPdf?: ElementRef[]) {
  //   htmlToPdf = this.allElements.value;
  //   const name = this.kidName.value;
  //   const maxWidth = htmlToPdf.map((v) => v.nativeElement.clientWidth);
  //   const maxHeight = htmlToPdf.map((v) => v.nativeElement.clientHeight);
  //   var HTML_Width = Math.max(...maxWidth);
  //   var HTML_Height = Math.max(...maxHeight);

  //   var top_left_margin = 15;
  //   var PDF_Width = HTML_Width + top_left_margin * 2;
  //   var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
  //   var canvas_image_width = HTML_Width;
  //   var canvas_image_height = HTML_Height;
  //   var pdf = new jsPDF('p', 'px', [PDF_Width, PDF_Height]);
  //   const canvas = await html2canvas(htmlToPdf[0].nativeElement, {
  //     allowTaint: true,
  //   });
  //   canvas.getContext('2d');
  //   var imgData = canvas.toDataURL('image/jpeg', 1.0);
  //   pdf.addImage(
  //     imgData,
  //     'JPG',
  //     top_left_margin,
  //     top_left_margin,
  //     canvas_image_width,
  //     canvas_image_height
  //   );
  //   if (htmlToPdf.length > 1) {
  //     for (let i = 1; i < htmlToPdf.length; i++) {
  //       pdf.addPage([HTML_Width, HTML_Height]);
  //       const loopCanvas = await html2canvas(htmlToPdf[i].nativeElement, {
  //         allowTaint: true,
  //       });

  //       HTML_Width = htmlToPdf[i].nativeElement.clientWidth;
  //       HTML_Height = htmlToPdf[i].nativeElement.clientHeight;
  //       loopCanvas.getContext('2d');

  //       var loopImgData = loopCanvas.toDataURL('image/jpeg', 1.0);
  //       pdf.addImage(
  //         loopImgData,
  //         'JPG',
  //         top_left_margin,
  //         top_left_margin,
  //         HTML_Width,
  //         HTML_Height
  //       );
  //     }
  //   }
  //   console.log(loopImgData === imgData);
  //   pdf.save(`Rapport de ${name} .pdf`);
  // }

  sendClickedButton(landing: string, button: string) {
    return this.http.get(
      `${environment.apiUrl}/api/addButtonClick/${landing}/${button}`,
      {
        context: new HttpContext().set(SKIPLOADING, true),
      }
    );
  }
  sendContactDetails(data: { landing: string; contact: string }) {
    return this.http
      .post(`${environment.apiUrl}/api/sendContactDetails`, data, {
        context: new HttpContext().set(SKIPLOADING, true),
      })
      .toPromise();
  }
  async checkNumber(number: number): Promise<boolean> {
    return (
      (await this.http
        .get(
          'https://phonevalidation.abstractapi.com/v1/?api_key=d4dfed6e635240688e75fc6484ea932f&phone=33' +
            number
        )
        .toPromise()) as any
    ).valid;
  }
}
